/* src/styles/ChessOrderComponent.css */

body {
    font-family: Arial, Helvetica, sans-serif;
}

.chess-order-container {
    display: flex;
    justify-content: center;
    width: 100%;
    background-size: cover;
}

.chess-order-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    margin: 20px 0;
    max-width: 1200px;
}

.chess-order-container.with-bg {
    background-size: cover;
    background-position: center;
}

.chess-text-container, .chess-image-container {
    flex: 1;
    text-align: center;
    max-width: 50%;
    padding: 20px;
}

.chess-title {
    text-transform: uppercase;
    /* font-family: Raleway-Light; */
    font-size: 22px;
    line-height: 32px;
    font-style: normal;
    font-weight: 300;
    color: #003580;
    text-decoration: none;
    letter-spacing: 0px;
    text-align: center;
    margin: 0 0 10px 0;
}

.chess-text {
    color: #003580;
    text-align: center;
    /* line-height: 1.2;  */
}

.chess-text-container.left {
    order: 1;
}

.chess-text-container.right {
    order: 2;
}

.chess-image-container.left {
    display: flex;
    justify-content: baseline;
    order: 1;
}

.chess-image-container.right {
    display: flex;
    justify-content: end;
    order: 2;
}

.chess-image-container img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .chess-order-box {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        margin: 10px 0;
        max-width: 1200px;
    }

    .chess-text-container {
        max-width: 80%;
  
    }

    .chess-text {
        color: #003580;
        text-align: center;
        font-size: 1.1rem;
        /* line-height: 1.2;  */
    }

    .chess-image-container {
        max-width: 80%;
    }

    .chess-text-container.left,
    .chess-text-container.right,
    .chess-image-container.left,
    .chess-image-container.right  {
        order: 0;
    }

    .chess-order-container {
        box-sizing: border-box;
        max-width: 100%;
    }

}
