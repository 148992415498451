/* src/components/Header.css */
.header {
    position: absolute;
    top: 2%;
    left: 0;
    width: 100%;
    z-index: 10;
    background-size: cover;
    background-position: center;
}

.top-row {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Добавлено: позволяет элементам переноситься на новую строку при необходимости */
}

.bottom-row {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Добавлено: позволяет элементам переноситься на новую строку при необходимости */
}

.logo {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 300px; /* Установлено начальное значение ширины */
    max-width: 100%; /* Убедитесь, что изображение не выходит за пределы контейнера */
    height: auto; /* Сохраняет пропорции изображения */
}

a img {
    max-width: 100%;
}

.logo-and-button {
    display: flex;
    align-items: center;
    gap: 30px;  /* Добавлено: создает пространство между логотипом и кнопкой */
}
.logo-and-button, .language-select {
    margin: 0 auto; /* центрирует элементы по горизонтали */
}

.book-button {
    background-color: #274286; /* Основной бренд-цвет */
    color: #f6f6f6; /* Дополнительный бренд-цвет */
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s ease; /* Добавлено для плавного перехода цвета */
}

.book-button:hover {
    background-color: #768daf; /* Дополнительный бренд-цвет при наведении */
}

.nav.desktop-nav {
    width: 100%;
    display: flex;
    justify-content: center;
}

.burger-button {
    display: none; /* по умолчанию скрыть кнопку бургер-меню */
}

.mobile-nav {
    display: none; /* по умолчанию скрыть мобильное меню */
}

.desktop-nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.desktop-nav a {
    text-decoration: none;
    color: #274286; /* Основной бренд-цвет */
}

.burger-button {
    margin-top: -53px;
    width: 35px;
    height: 30px;
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.burger-button:before,
.burger-button:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #274286;  /* Основной цвет сайта */
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.burger-button:before {
    top: 8px;
}

.burger-button:after {
    top: 18px;
}

/* .burger-button:hover:before,
.burger-button:hover:after {
    background-color: #768daf;}  Светлый цвет при наведении */

@media only screen and (max-width: 768px) {

    .language-select {
        width: auto;
        margin-right: 5px;
    }

    .mobile-select-control {
        border-color: transparent !important;
        color: #274286 !important;
        box-shadow: none !important;
    }

    .logo {
        display: flex;
        
        align-items: center;
        gap: 20px;
        width: 180px; /* Установлено начальное значение ширины */
        max-width: 100%; /* Убедитесь, что изображение не выходит за пределы контейнера */
        height: auto; /* Сохраняет пропорции изображения */
    }
    
    a img {
        max-width: 100%;
    }

    .top-row, .bottom-row {
        justify-content: center; /* Выравнивает элементы по центру на мобильных устройствах */
        flex-direction: column; /* Переключает на вертикальное выравнивание на мобильных устройствах */
    }

    /* Стили для мобильной версии */
    .nav.mobile-nav {
        flex-direction: column;
        align-items: center;
    }

    .mobile-nav ul {
        padding: 20px 30px 20px 40px;
        z-index: 9;
        list-style-type: none;
        margin: 0;
        display: flex;
        flex-direction: column;
    }

    .mobile-nav li {
        margin: 5px 0;
        border-bottom: 1px solid #274286; /* Добавляет границу снизу каждого пункта меню */
        padding-bottom: 5px; /* Добавляет немного отступа снизу, чтобы граница не касалась текста */
    }

    .mobile-nav li:last-child {
        border-bottom: none; /* Убирает границу у последнего пункта меню, чтобы избежать двойной границы */
    }

    .mobile-nav a {
        text-decoration: none;
        color: #274286; /* Основной бренд-цвет */
    }

    .burger-button {
        display: block;
    }

    .mobile-nav {
        display: block;
    }

    .desktop-nav {
        display: none;
    }
}
