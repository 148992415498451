.footer_ {
    display: flex;
    justify-content: center;
    background-color: #274286;
    color: #fff;
    padding-top:0px;
    padding-bottom: 0px;
    text-align: center;
}

.footer-max-width {
    width: 90%; 
    padding: 0px;
}

.footerLogo{
    display: flex;
    justify-content: center;
    align-items: center;
   margin-bottom: 10px;
}
.footerTop{
    display: flex;
    text-wrap: nowrap;
    justify-content: center;
    align-items: center;
   margin-bottom: 25px;
 
}

.footer-logo-container {
    text-align: left;
}
.footer-logo {
    max-width: 180px;
}

.my-nav {
    display: flex;
    justify-content: flex-end;
}


.nav-link {
    color: #fff;
    font-size: 18px;
    margin: 0 10px;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    margin-left: 7px;
    margin-right: 7px;
    padding: 7px 7px 0 7px;
    border-bottom: 1px solid white;
}

.nav-link:hover {
    color: #ccc;
    border: none;
}

.active {
    border-radius: 3px;
    
    background-color:  rgba(157, 175, 202, 0.268);
    border-bottom: 3px solid #a4b3da;
}

.footerBottom {
    display: flex;
    justify-content: center;
    align-items: center;
   margin-bottom: 10px;
}

.my-social {
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.social-icon {
    color: #fff;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 20px; /* Устанавливаем размер иконок */
    transition: color 0.3s ease-in-out;
}

.social-icon:hover {
    color: #ccc;
}

.copyright {
    color: #ffffff;
    font-size: 14px;
}
p {
    margin: 0;
}

@media (max-width: 991px) {
    .footer-max-width {
        max-width: 100%;
    }

    .footerLogo, .footerTop, .footerBottom {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .footer-logo-container, .my-nav, .my-social {
        justify-content: center;
        text-align: center;
        margin-bottom: 10px;
    }

    .my-nav {
        flex-direction: column; 
        align-items: center;
    }

    .nav-link {
        display: block; 
        margin: 5px 0; 
        text-align: center;
        font-size: 16px;
    }
    .my-social {
         margin: 0;
    }
}
