/* src/styles/RentalTerms.css */
body {
    font-family: Arial, Helvetica, sans-serif;
}


.rental-terms-page-section {
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 200px;
}

.section-title {
    z-index: 9;
    font-size: 2rem;
    color: #274286;
    text-align: center;
    margin-bottom: 20px;
}

.contract-content {
    padding-left: 360px;
    padding-right: 360px;
    font-size: 1.25rem;
    text-align: left;
    color: #274286;
    border-radius: 10px;
    margin: 20px 0 70px 0;
}

h2 {
    border-bottom: 2px solid #274286;
    padding-bottom: 10px;
    margin-top: 20px;
}

h3 {
    margin: 10px 0 10px 0;
    color: #274286;
}

h4 {
    margin-top: 10px;
    font-weight: bold;
}

p, ul {
    margin: 15px 0;
    line-height: 1.5;

}

ul {
    margin-left: 30px;
}

@media (max-width: 768px) {
    .rental-terms-page-section {
        padding-top: 100px;
    }

    .section-title {
        font-size: 1.5rem;
    }

    .contract-content {
        font-size: 1rem;
        padding: 15px;
        max-width: 100%;
    }

    h2, h3, h4 {
        margin-top: 10px;
    }

    p, ul {
        margin: 10px 0;
    }

    ul {
        margin-left: 20px;
    }
}