/* src/styles/Contact.css */

body {
	margin: 0;
	padding: 0;
	font-family: Arial, Helvetica, sans-serif;
}

.contact-page {
	background-image: url(../../public/bg-map.jpg);
	min-height: min-content;
	text-align: center;
	box-sizing: border-box;
	margin-top: 200px;
}

.contact-content {
	flex-grow: 1; /* Allow the content to take all available space */
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	padding-bottom: 40px;
}

.left-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.right-content {
	border-radius: 5px;
	background-color: #dfdfdf;
}

.social-network {
	max-width: 30px;
	margin: 5px;
	opacity: 0.8;
	color: #000080;
}

.social-network:hover {
	opacity: 1;
}

.form-content {
	padding: 20px;
	display: flexbox;
	width: 400px;
	height: 400px;
}

.name-box {
	width: min-content;
}

.email-box {
	width: min-content;
}

.phone-box {
	width: 216px;
}

.error-message {
	color: #d32f2f;
	font-style: italic;
	font-size: 0.8rem;
	width: 100%;
	text-align: center;
}

.error {
	color: red;
	font-size: small;
	padding: 0 0 7px;
}

.PhoneInputCountry {
	margin-left: 3px;
	margin-bottom: 10px;
}

.btn {
	background-color: #274286;
	cursor: pointer;
	color: #ffffff;
}

.textarea {
	font-family: Arial, Helvetica, sans-serif;
}

h1 {
	font-size: 2rem;
	color: #274286; /* You can adjust the color according to your design */
}

h5 {
	font-size: 20px;
	/* font-family:  'Raleway-Medium'; */
	color: #003580; /* You can adjust the color according to your design */
}

span {
	font-size: 20px;
	color: #000080; /* You can adjust the color according to your design */
}

p {
	font-size: 1rem;
	color: #555; /* You can adjust the color according to your design */
}

iframe {
	height: 200px;
	border: 0;
	border-radius: 5px;
	box-shadow: 0 0 5px black;
	margin-top: 30px;
}

.phone-input,
input,
textarea {
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 5px;
	font-size: 16px;
	border: 1px solid #ccc;
	background-color: white;
	box-shadow: 0 0 2px rgba(39, 66, 134, 0.5);
}

textarea {
	width: 378px;
}

.PhoneInputInput:focus,
.textarea:focus,
.input:focus {
	outline: none;
	border-color: #274286;
	box-shadow: 0 0 5px rgba(39, 66, 134, 0.5);
}

@media only screen and (max-width: 768px) {
	.contact-page {
		margin-top: 100px;
		padding-left: 10px;
		padding-right: 10px;
		width: 100%;
		min-height: auto;
		padding-bottom: 50px; 
	}

	.contact-content {
		flex-direction: column;
	}

	.left-content,
	.right-content {
		align-items: center;
		width: 100%;
	}

	.right-content {
		margin-top: 30px;
	}

	.form-content {
		box-sizing: border-box;
		width: 100%;
		align-items: center;
	}

	.name-box,
	.email-box,
	.phone-box,
	.textarea,
	.input,
	.btn {
		resize: none;
		box-sizing: border-box;
		width: 100%;
	}

	h1 {
		font-size: 1.5rem;
	}

	h5,
	span,
	p {
		font-size: 16px;
	}

	iframe {
		width: 100%;
		height: 150px;
	}
}

@media (min-width: 769px) {
	.contact-page {
			padding-bottom: 0;
	}
}