/* src/styles/Training.css */

.training-page-section {
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Изменено с center на flex-start */
    align-items: center;
    padding-top: 200px; /* Добавлен верхний отступ */
}

.section-title {
    z-index: 9;
    font-size: 2rem;
    color: #274286;
    text-align: center;
    margin-bottom: 20px;
}

.training-text {
    z-index: 9;
    font-size: 1.25rem;
    color: #274286;
    text-align: left;
    margin-bottom: 50px;
    padding-left: 360px;
    padding-right: 360px;
}

@media only screen and (max-width: 768px) {
    .page-content {
        padding-left: 2%;
        padding-right: 2%;
    }

    .training-page-section {
        padding-top: 150px; /* Добавлен верхний отступ */
        height: auto; /* Позволяет секции растягиваться в зависимости от контента */
        min-height: 100vh; /* Убедитесь, что минимальная высота секции равна высоте видимой области */
        padding-bottom: 20px;
        background-size: cover;
        background-attachment: fixed;
    }

    .training-text {
        font-size: 1.1rem;
        text-align: center;
        margin-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
