/* src/styles/Houseboat.css */

.houseboat-page-section {
    min-height: min-content;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Изменено с center на flex-start */
    align-items: center;
    padding-top: 200px; /* Добавлен верхний отступ */
}

.round-button {
    z-index: 9999;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 2px solid #274286;
    overflow: hidden;
    padding: 0;
    cursor: pointer;
    position: fixed;
    right: 2%;
    bottom: 5%;
}

.round-button img {
    width: 100%;
    height: auto;
}

.hidden {
    display: none;
}

.section-title {
    z-index: 9;
    font-size: 2rem;
    color: #274286;
    text-align: center;
    margin-bottom: 20px;
}

.buttons-row {
    display: flex;
    justify-content: space-around;
    align-items: center; /* Выравнивание кнопок по центру по вертикали */
    flex-wrap: wrap;
    gap: 3rem;
    margin-top: 50px;
}

.button-block {
    flex: 1 0 auto;
    margin: 0 35px 30px 35px;
    min-width: 200px;
    min-height: 550px;
    text-align: center;
    position: relative; /* Добавлено для позиционирования текста относительно кнопки */
}

.button-item {
    width: 100px; /* или любое другое значение */
    height: 100px; /* или любое другое значение */
    background-size: cover; /* чтобы изображение полностью покрывало кнопку */
    background-position: center; /* чтобы изображение было по центру кнопки */
    border: none; /* убираем рамку вокруг кнопки */
    border-radius: 50%;
    cursor: pointer; /* делаем курсор в виде руки при наведении */
    background-color: transparent;
}

.button-title {
    position: absolute; /* Абсолютное позиционирование относительно button-block */
    width: 100%; /* Ширина соответствует button-block */
    top: 100px;
    font-size: 1.25rem; /* устанавливаем размер шрифта */
    color: #274286;
}

.button-description {
    position: absolute; /* Абсолютное позиционирование относительно button-block */
    width: 100%; /* Ширина соответствует button-block */
    top: 150px;
    text-align: center;
    overflow: visible; /* позволяет тексту выходить за границы элемента */
    text-overflow: clip; /* убирает многоточие (...) в конце обрезанного текста */
    white-space: normal; /* позволяет тексту переноситься на новую строку */
    color: #274286;
    font-size: 1.25rem;
    margin: 20px 0 0 0;
}

.transparent-button {
    margin-top: auto; /* Автоматический верхний отступ выталкивает кнопку вниз */
    padding: 10px 20px; /* Отступы внутри кнопки */
    border: none; /* Убираем рамку */
    background-color: #274286; /* Полупрозрачный белый */
    color: #f6f6f6;
    box-shadow: 0 0 3px white;
    font-size: 16px; /* Размер шрифта */
    cursor: pointer; /* Курсор в виде руки */
    border-radius: 5px; /* Скругленные углы */
    transition: background-color 0.3s ease; /* Плавный переход для background-color */
    margin-bottom: 7vmax;
}

.transparent-button:hover {
    background-color: #768daf; /* Более непрозрачный при наведении */
}

.gallery.active {
    visibility: visible;
    opacity: 1;
}

.gallery {
    z-index: 1001;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 397px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 2s ease-in-out, visibility 2s ease-in-out;
}

.gallery img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
}

.cl-btn-gallery {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    position: relative;
    left: 597px;
    top: -37px;
    z-index: 10000;
    margin: 20px auto;
    cursor: pointer;
}
.cl-btn-gallery:before {
    content: '+';
    color: rgb(255, 255, 255);
    content-visibility: 1;
    position: absolute;
    z-index: 2;
    transform: rotate(45deg);
    font-size: 50px;
    line-height: 1;
    top: -5px;
    left: 4px;
    transition: all 0.3s cubic-bezier(0.77, 0, 0.2, 0.85);
}
.cl-btn-gallery:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: #768daf;
    z-index: 1;
    transition: all 0.3s cubic-bezier(0.77, 0, 0.2, 0.85);
    transform: scale(0.01);
}
.cl-btn-gallery:hover:after {
    transform: scale(1);
}
.cl-btn-gallery:hover:before {
    transform: scale(0.8) rotate(45deg);
    color: #fff;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Цвет и прозрачность оверлея (черный с 50% прозрачности) */
    z-index: 11; /* Устанавливаем оверлей поверх других элементов */
  }

@media only screen and (max-width: 768px) {
    .page-content {
        padding-left: 2%;
        padding-right: 2%;
    }

    .houseboat-page-section {
        padding-top: 150px; /* Добавлен верхний отступ */
        height: auto; /* Позволяет секции растягиваться в зависимости от контента */
        min-height: 100vh; /* Убедитесь, что минимальная высота секции равна высоте видимой области */
        padding-bottom: 50px;
        background-size: cover;
        background-attachment: fixed;
    }

    .round-button {
        width: 80px;
        height: 80px;
        right: 5%;
        bottom: 5%;
    }

    .button-block {
        min-height: auto;
        margin-bottom: 0; /* добавляет пространство между блоками кнопок на мобильных устройствах */
    }

    .button-title, .button-description {
        position: static; /* отключает абсолютное позиционирование на мобильных устройствах */
        margin-top: 10px; /* добавляет пространство между кнопкой и текстом */
    }

    .button-description {
        margin-top: 5px; /* добавляет пространство между заголовком и описанием */
        font-size: 1.1rem;
        text-align: center;
    }

    .transparent-button {
        margin-top: 50px; /* Автоматический верхний отступ выталкивает кнопку вниз */
        margin-bottom: 20px;
    }

    .gallery {
        width: calc(100% - 40px); /* Уменьшаем ширину на 40px (20px с каждой стороны) */
        height: auto; /* Высота автоматически рассчитывается на основе пропорций изображения */
        max-height: 80vh; /* Ограничиваем высоту, чтобы оставить немного пространства сверху и снизу */
        left: 50%; /* Позиционируем по центру экрана */
        transform: translateX(-50%);
    }
}

