.back-button {
    position: fixed;
    bottom: 55px;
    right: 40px;
    background-color: #274286; /* Adjust the color according to your design */
    color: #fff;
    padding: 10px 20px;
    border: 1px solid #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.back-button:hover {
    background-color: #456994; /* Adjust the hover color according to your design */
}