.synthetic-popup {
    position: fixed;
    bottom: 3%;
    right: 2%;
    width: 200px;
    height: 200px;
    background-color: transparent;
    box-shadow: none;
    align-items: center;
    padding: 10px 0;
    z-index: 9999;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.3s, transform 0.3s;
}

.synthetic-popup.visible {
    opacity: 1;
    transform: scale(1);
}

.avatar-video {
    border-radius: 50%;
    border: 2px solid #274286;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    display: block;
    margin: 0;
}

.question-input-popup {
    width: 90%;
    padding: 5px;
    margin: 10px auto;
    border: 1px solid #dedce9;
    border-radius: 5px;
}

.volume-control-popup {
    position: absolute; /* Абсолютное позиционирование относительно ближайшего родителя с position: relative; */
    bottom: 15px; /* Отступ снизу */
    left: 25px; /* Отступ слева */
    display: flex;
    align-items: center;
    width: 50%;
    margin: auto 5px;
}

.volume-icon-popup {
    margin-right: 10px;
    font-size: 1.2rem;
}

.volume-slider-popup {
    flex-grow: 1;
    width: 60%; /* Устанавливаем ширину ползунка */
}

.buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.yes-button, .no-button {
    margin: 10px 5px;
    padding: 5px 15px;
    border-radius: 5px;
    border: 1px solid #f6f6f6;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-sizing: border-box; /* Убедитесь, что padding включен в общую ширину кнопки */
}

.yes-button {
    background-color: #274286; /* Основной бренд-цвет */
    color: #f6f6f6; /* Дополнительный бренд-цвет */
}

.yes-button:hover {
    background-color: #768daf; /* Цвет при наведении */
}

.no-button {
    background-color: #274286; /* Основной бренд-цвет */
    color: #f6f6f6; /* Дополнительный бренд-цвет */
}

.no-button:hover {
    background-color: #768daf; /* Цвет при наведении */
}



.start-test-button {
    background-color: #459407; /* Основной цвет фона */
    border: none;
    color: #f7f6f2; /* Цвет текста */
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    transition: background-color 0.3s;
    text-decoration: none;
    display: inline-block;
    margin: 10px 5px;
    padding: 5px 15px;
}

.start-test-button:hover {
    background-color: #649f0c; /* Цвет фона при наведении */
}

.popup-controls {
    position: absolute;
    top: -10px;
    left: -10px; /* Установите значение 0, чтобы элемент растягивался на всю ширину родителя */
    right: 0; /* Установите значение 0, чтобы элемент растягивался на всю ширину родителя */
    display: flex;
    justify-content: left; /* Выравнивание элементов по центру */
    align-items: center;
}

.close-icon, .play-icon, .pause-icon {
    cursor: pointer;
    font-size: 25px;
    margin-right: 10px;
    transition: opacity 0.3s;
}

.close-icon:hover, .play-icon:hover, .pause-icon:hover {
    opacity: 0.7;
}

.cl-btn-7 {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    position: relative;
    right: 90px;
    bottom: 10px;
    z-index: 1;
    margin: 20px auto;
    cursor: pointer;
}
.cl-btn-7:before {
    content: '+';
    color: #274286;
    text-shadow: -0.3px -0.3px 0 #fff, 0.3px -0.3px 0 #fff, -0.3px 0.3px 0 #fff, 0.3px 0.3px 0 #fff;
    content-visibility: 1;
    position: absolute;
    z-index: 2;
    transform: rotate(45deg);
    font-size: 50px;
    line-height: 1;
    top: -5px;
    left: 4px;
    transition: all 0.3s cubic-bezier(0.77, 0, 0.2, 0.85);
}
.cl-btn-7:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: #768daf;
    z-index: 1;
    transition: all 0.3s cubic-bezier(0.77, 0, 0.2, 0.85);
    transform: scale(0.01);
}
.cl-btn-7:hover:after {
    transform: scale(1);
}
.cl-btn-7:hover:before {
    transform: scale(0.8) rotate(45deg);
    color: #fff;
}

@media only screen and (max-width: 768px) {
    .synthetic-popup {
        bottom: 3%;
        right: 3%;
        width: 100px;
        height: 100px;
    }
}
