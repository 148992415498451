/* src/styles/Home.css */

.section {
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.round-button {
    z-index: 9999;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 2px solid #274286;
    overflow: hidden;
    padding: 0;
    cursor: pointer;
    position: fixed;
    right: 2%;
    bottom: 5%;
}

.round-button img {
    width: 100%;
    height: auto;
}

.hidden {
    display: none;
}

.section-title {
    z-index: 9;
    font-size: 2rem;
    color: #274286;
    text-align: center;
    margin-bottom: 20px;
}

.section-1 {
    margin-top: 200px;
}

.section-2 {
    margin-top: 70px;
}

.main-page-text {
    z-index: 9;
    font-size: 1.25rem;
    color: #274286;
    text-align: center;
    margin:0 100px 30px 100px;
    padding: 0 250px;
    line-height: 1.5; 
}

.gallery-section {
    width: 50%;
    min-height: auto;
    margin-bottom: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.image-gallery-image img {
    max-width: 100%; /* Ограничиваем максимальную ширину изображения */
    height: auto;    /* Поддерживаем пропорции изображения */
    display: block;  /* Убираем пробелы под изображением */
    margin: 0 auto;
}

.image-gallery-thumbnails img {
    max-width: 100%;
    height: auto;
}

.image-gallery-content.fullscreen img {
    object-fit: contain;
    height: 100vh;
    width: 100vw;
    margin: auto;
    display: block;
    position: relative;
    top: 50%;
}

.buttons-row {
    display: flex;
    justify-content: space-around;
    align-items: center; /* Выравнивание кнопок по центру по вертикали */
    flex-wrap: wrap;
    gap: 3rem;
}

.button-block {
    flex: 1 0 auto;
    margin: 10px;
    min-width: 200px;
    text-align: center;
    position: relative; /* Добавлено для позиционирования текста относительно кнопки */
}

.button-title, .button-description {
    position: absolute; /* Абсолютное позиционирование относительно button-block */
    width: 100%; /* Ширина соответствует button-block */
}

.button-item {
    width: 100px; /* или любое другое значение */
    height: 100px; /* или любое другое значение */
    background-size: cover; /* чтобы изображение полностью покрывало кнопку */
    background-position: center; /* чтобы изображение было по центру кнопки */
    border: none; /* убираем рамку вокруг кнопки */
    cursor: pointer; /* делаем курсор в виде руки при наведении */
    background-color: transparent;

}

.button-title {
    top: 100px;
    font-size: 1.2rem; /* устанавливаем размер шрифта */
    color: #274286;
}

.button-description {
    top: 150px;
    text-align: center;
    overflow: visible; /* позволяет тексту выходить за границы элемента */
    text-overflow: clip; /* убирает многоточие (...) в конце обрезанного текста */
    white-space: normal; /* позволяет тексту переноситься на новую строку */
    color: #274286;
    font-size: 1.25rem;
}

@media only screen and (max-width: 768px) {
    .page-content, .section {
        padding-left: 2%;
        padding-right: 2%;
    }
    .section-2 {
        margin-top: 70px;
    }
    .round-button {
        width: 80px;
        height: 80px;
        right: 5%;
        bottom: 5%;
    }
    .main-page-text {
        margin: 0 10px 30px 10px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 1.1rem;
    }
    .gallery-section {
        width: 90%;
        margin-top: -5px; /* Поднимает галерею вверх */
    }

    .image-gallery-image img {
        max-height: 50vh; /* Ограничиваем максимальную высоту изображения */
    }

    .image-gallery-thumbnails-wrapper {
        overflow-x: auto; /* Добавляем прокрутку по горизонтали, если миниатюры не умещаются */
    }

    .image-gallery-thumbnail {
        max-width: 100%; /* Ограничиваем максимальную ширину миниатюры */
        height: auto;    /* Поддерживаем пропорции изображения */
    }

    .image-gallery-thumbnail img {
        max-height: 50px; /* Ограничиваем максимальную высоту изображения миниатюры */
    }

    .buttons-row {
        flex-direction: column; /* выстраиваем блоки вертикально на мобильных устройствах */
    }

    .button-block {
        margin: 2vw; /* увеличиваем отступ снизу для мобильных устройств */
    }
}
