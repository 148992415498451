/* src/styles/Blog.css */

body {
    margin: 0;
    padding: 0;
}

.booking-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.booking-content {
    flex-grow: 1; /* Allow the content to take all available space */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h1 {
    font-size: 2rem;
    color: #274286; /* You can adjust the color according to your design */
}

p {
    font-size: 1rem;
    color: #555; /* You can adjust the color according to your design */
}
