/* src/styles/Booking.css */

body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}

.booking-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.booking-content {
    flex-grow: 1; /* Allow the content to take all available space */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h1 {
    font-size: 2rem;
    color: #274286; /* You can adjust the color according to your design */
}

.booking-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f6f6f6;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
    color: #274286;
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
}

input, textarea {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #dedce9;
    border-radius: 5px;
    font-size: 16px;
}

.error-message {
    color: red;
    margin-bottom: 10px;
}

.error {
    color: red;
    font-size: small;
    padding: 0 0 7px;
}

.errorRequired {
    color: red;
    font-size: small;
    padding: 7px 7px 7px;
}

.message-lenght {
    font-size: 14px;
    padding: 0 0 5px;
}

button {
    padding: 10px 20px;
    background-color: #274286;
    color: #f6f6f6;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

/* button:hover {
    background-color: #768daf;} */

@media only screen and (max-width: 768px) {
    textarea {
        width: auto;
        resize: none;
    }
}
