/* src/styles/SafetyOnWater.css */

body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}

.safety-water-page {
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 200px;
}

.safety-water-content {
    z-index: 9;
    text-align: center;
    margin-bottom: 20px;
}

.section-title {
    text-align: center;
}

.section-title-description {
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    font-style: Arial, Helvetica, sans-serif;
    color: #274286;
    font-size: 1.25rem;
}

h1 {
    font-size: 2rem;
    color: #274286; /* You can adjust the color according to your design */
}

p {
    font-size: 1.25rem;
    color: #274286; /* You can adjust the color according to your design */
}
@media (max-width: 768px) {
    .safety-water-page {
        padding-top: 100px; /* Reduced padding for smaller screens */
    }

    .section-title-description {
        font-size: 1.1rem;
    }

    h1 {
        font-size: 1.5rem; /* Smaller font size for the title */
    }

    p {
        font-size: 1.1rem; /* Slightly smaller font size for paragraphs */
    }
}