/* src/styles/ActiveHoliday.css */

body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}

.active-holiday-page {
    min-width: 100%;
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 200px;
}

.active-holiday-content {
    z-index: 9;
    text-align: center;
    margin-bottom: 20px;
}

.section-title {
    z-index: 9;
    font-size: 2rem;
    color: #274286;
    text-align: center;
    margin-bottom: 20px;
}

h1 {
    font-size: 2rem;
    color: #274286; /* You can adjust the color according to your design */
}

p {
    font-size: 1rem;
    color: #000000; /* You can adjust the color according to your design */
}

.fishing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../public/bg-sale.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.info {
    width: 50%;
    text-align: center;
}

.booking {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 35px;
    padding-bottom: 35px;
    text-align: left;
}

.button {
    color: #fff !important;
    background-color: #003580;
    border: none;
    border-radius: 3px;
    z-index: 10;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.16);
    font-size: 12px;
    /* font-family: Raleway-Medium; */
    padding: 0 20px;
    margin-left: 10px;
    top: 20px;
    height: 40px;
    line-height: 40px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    height: 40px;
    line-height: 40px;
    text-decoration: none;
    cursor: pointer;
    opacity: .9;
}

.button:hover {
    opacity: 1;
}

.fishing-text {
    margin: 0 0 30px 0;
    font-size: 1.25rem;
    color: #274286;
    
}

.permits-text{
    margin: 0 0 20px 0;
}

.rules {
    background-color: rgba(39, 66, 134, 0.264);
    color: #003580;
    width: 50%;
    text-align: center;
}
.rules-title {
    font-size: 1.5rem;
    margin: 10px 0 10px 0;
}

@media only screen and (max-width: 768px) {
    .active-holiday-page {
        padding-top: 100px;
    }

    .section-title, h1 {
        font-size: 1.5rem;
    }

    p {
        font-size: 1.25rem;
    }

    .fishing-text {
        font-size: 1.1rem;
        color: #274286;
    }

    .permits-text{
        font-size: 1.1rem;
    }
    .fishing-container {
        padding: 10px;
        background-size: cover;
        justify-content: center;
    }

    .info, .rules {
        box-sizing: border-box;
        width: 100%;
    }

    .booking {
        box-sizing: border-box;
        flex-direction: column;
        padding: 10px;
        margin: 10px;
        text-align: center;
    }

    .button {
        font-size: 10px;
        padding: 0 15px;
        margin: 5px 0;
        height: 35px;
        line-height: 35px;
    }
}