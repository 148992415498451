body {
  font-family: Arial, Helvetica, sans-serif;
}

.trio-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  width: 100%;
}

.trio-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  max-width: 1200px;
}

.trio-container.with-bg {
  background-size: cover;
  background-position: center;
}

.trio-title-container {
  text-transform: uppercase;
  /* font-family: Raleway-Light; */
  font-size: 22px;
  line-height: 32px;
  font-style: normal;
  font-weight: 300;
  color: #003580;
  text-decoration: none;
  letter-spacing: 0px;
  text-align: center;
  margin: 0 0 10px 0;
}

.trio-text-container {
  flex: auto;
  padding: 0 20px 20px 20px;
  color: #003580;
  text-align: center;
  max-width: 80%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.25rem;
  line-height: 1.5;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  letter-spacing: 0px;
  margin: 0 auto;

}

.trio-image-container {
  display: flex;
  justify-content: space-around;

  max-height: 640px;
  box-sizing: border-box;
  width: 100%;
}

.trio-image-container img {
  max-height: 350px;
  padding-left: 15px;
  padding-right: 15px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted grey;
  font-size: 1.25rem;
}

.tooltip-content {
  display: none;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1;
  top: -160%;
  left: 125%;
  transform: translateX(-50%);
}

.tooltip-container:hover .tooltip-content {
  display: block;
}

@media only screen and (max-width: 768px) {
  .trio-image-container {
    box-sizing: border-box;
    max-width: 100%;
    overflow-x: scroll;
    justify-content: start;
    mask-image: linear-gradient(to left, transparent 0, black 20px, black calc(100% - 20px), transparent 100%);
  }

  .trio-image-container img {
    width: 90%;
  }

  .trio-text-container {
    font-size: 1.1rem;
  }

  .trio-box {
    max-width: 100%;
  }

  .trio-container {
    box-sizing: border-box;
    width: 100%;
    height:auto
  }

}
